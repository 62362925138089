import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgClass, NgIf } from '@angular/common';
import {
    ActivatedRoute,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterLink,
    RouterOutlet
} from '@angular/router';
import { Base64 } from 'js-base64';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { environment } from '../../../environments/environment';
import { CrumbInterface } from '../../shared/interfaces/crumb.interface';
import { WindowSizeInterface } from '../../shared/interfaces/window-size.interface';
import { Auth } from '../../shared/models/auth.model';
import { Loader } from '../../shared/models/loader.model';
import { AuthService } from '../../shared/services/auth.service';
import { CurrentClientService } from '../../shared/services/current-client.service';
import { LoaderService } from '../../shared/services/loader.service';
import { RoutesService } from '../../shared/services/routes.service';
import { SettingsService } from '../../shared/services/settings.service';
import { WindowService } from '../../shared/services/window.service';
import { QueuesDBService } from '../../shared/services/queues-db.service';
import { DebugService } from '../../shared/services/debug.service';
import { PushNotificationService } from '../../shared/services/push-notification.service';
import { AlertsComponent } from './components/alerts/alerts.component';
import { MainMenuComponent } from './components/navigation/main-menu/main-menu.component';
import { UserNavComponent } from './components/navigation/user-nav/user-nav.component';
import { BreadcrumbComponent } from './components/navigation/breadcrumb/breadcrumb.component';
import { ClientMenuComponent } from './components/navigation/client-menu/client-menu.component';
import { QrCodeComponent } from './components/navigation/qr-code/qr-code.component';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.css'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        NgClass,
        RouterOutlet,
        ModalModule,
        AlertsComponent,
        MainMenuComponent,
        UserNavComponent,
        BreadcrumbComponent,
        ClientMenuComponent,
        QrCodeComponent
    ],
    providers: [
        BsModalService
    ]
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {

    @ViewChild(BreadcrumbComponent) breadcrumbComponent!: BreadcrumbComponent;

    public title: string;
    public pageClass: string;
    public pageClassTips: string;
    public pageClassFirstSteps: string;
    public pageClassRequired: string;
    public pageClassMultiusers: string;
    public accountId: number;
    public userId: number;
    public userName: string;
    public userPhoto: string | Int32Array;
    public clientId: number = null;
    public clientType: string = null;
    public clientName: string = null;
    public items: Array<CrumbInterface> = [];
    public currentConfig: Auth;
    public logoHeader: string;
    public fcTitle = 'Food Checker';
    public registered: boolean;

    public loader: Loader;
    public loaderNavigation: boolean;

    public isIos = /iPhone|iPad|iPod/.test(navigator.userAgent);
    public isAndroid = /Android/.test(navigator.userAgent);

    @Input() values: WindowSizeInterface;

    constructor(
        public currentClientService: CurrentClientService,
        public settingsService: SettingsService,
        protected authService: AuthService,
        protected toastrService: ToastrService,
        protected loaderService: LoaderService,
        protected route: ActivatedRoute,
        private pushNotificationService: PushNotificationService,
        private routesService: RoutesService,
        private titleService: Title,
        private router: Router,
        private windowService: WindowService,
        private queuesDBService: QueuesDBService,
        private debugService: DebugService,
    ) {
        this.loader = this.loaderService.loader;

        if (this.router.url === '/registered-successfully') {
            this.registered = true;
        }

        // change routes
        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loaderNavigation = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {

                    // breadcrumb
                    const url = this.router.url.split('?')[0];
                    this.routesService.url = url;
                    this.generateBreadcrumbTrail(url);

                    // body css
                    this.pageClass = (url.replace(/[0-9]/g, '')
                            .replace(/\/\//g, '/')
                            .replace(/\//g, ' p-')
                            .replace(/^\s+|\s+$/g, '')) +
                        ' role-' + (this.authService.currentUserValue ? this.authService.currentUserValue.role : '') +
                        ' subscriber-' + ((localStorage.getItem('subscriber') + '').toLowerCase()) +
                        ' subscriber-' + ((localStorage.getItem('situation') + '').toLowerCase()) +
                        ' plan-' + ((localStorage.getItem('plan') + '').toLowerCase()) +
                        ' ' + (localStorage.getItem('css') + '');

                    document.body.setAttribute('page', (url.replace(/[0-9]/g, '')
                        .replace(/\/\//g, '/')
                        .replace(/\//g, ' p-')
                        .replace(/^\s+|\s+$/g, '')));

                    // body css required fields
                    this.pageClassTips = localStorage.getItem('hTips');
                    this.pageClassFirstSteps = localStorage.getItem('hFirstSteps');
                    this.pageClassRequired = localStorage.getItem('hRequired');
                    this.pageClassMultiusers = this.authService.currentUserValue ?
                        (this.authService.currentUserValue.account.multiusers ? 'multiusers' : '') : '';

                    // page title
                    this.setTitle();

                    // loading
                    setTimeout(() => {
                        this.loaderNavigation = false;
                    }, 200);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        document.body.classList.add('app-admin');

        if (this.settingsService.getBool('no-delete')) {
            document.body.classList.add('hide-btn-delete');
        }
    }

    ngOnInit() {

        // set settings
        this.settingsService.setSettings();
        this.setAppearance();

        // set user menu
        this.currentConfig = this.authService.currentUserValue;
        const name = Base64.decode(this.currentConfig.name).split(' ');
        this.userName = name[0] + ' ' + (name.length > 1 ? name[name.length - 1] : '');
        this.userPhoto = Base64.decode(this.currentConfig.avatar);
        this.accountId = this.currentConfig.account.id ? Number(Base64.decode(String(this.currentConfig.account.id))) : null;
        this.userId = Number(Base64.decode(String(this.currentConfig.id)));
        const userEmail = Base64.decode(this.currentConfig.email);

        // set client
        setInterval(() => {
            if (this.clientId !== this.currentClientService.id || this.clientName !== this.currentClientService?.client?.name) {
                this.clientId = this.currentClientService?.id;
                this.clientType = this.currentClientService?.client?.type;
                this.clientName = this.currentClientService?.client?.name;
                this.setTitle();
            }
        }, 1000);

        // global vars
        // @ts-ignore
        window.subscriptionId = this.accountId;
        try {
            // @ts-ignore
            window.userEmail = Base64.decode(this.currentConfig.email);
        } catch (e) {
            console.log(this.currentConfig);
            console.error(e.message);
        }
        // @ts-ignore
        window.subscriptionValue = 0;

        // window size
        this.windowService.windowSizeChanged.subscribe(
            value => this.values = value
        );

        // sinaliza o acesso de desenvolvimento
        document.body.classList.remove('environment-development');
        if (!environment.production || userEmail === 'juliana@saudeviver.com.br') {
            document.body.classList.add('environment-development');
        }

        // access
        setTimeout(() => {
            this.sendAccess();
        }, 1000 * 10); // 10 segundos

        // debug data failed sync
        setTimeout(() => {
            this.debugSyncDataFaileds();
        }, 1000 * 60); // 60 segundos

        // push notification
        this.pushNotificationService.initializeWithAppState();
    }

    ngAfterViewInit() {
        this.breadcrumbComponent.generateBreadcrumbTrail(this.router.url.split('?')[0]);
    }

    public generateBreadcrumbTrail(url: string): void {
        if (this.breadcrumbComponent) {
            this.breadcrumbComponent.generateBreadcrumbTrail(url);
        }
    }

    public backButtonNavigate(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        const parentRoute = target.parentElement.getAttribute('data-parent');
        if (parentRoute) {
            this.router.navigate([parentRoute]);
        }
    }

    private sendAccess(): void {
        if (!environment.production) { return; }
        if (!navigator.onLine) {
            console.log('Não conectado à internet.');
            return;
        }
        this.authService.get('set_access', {
                others: { noLoader: true }
            }
        ).subscribe();
    }

    private setAppearance(): void {
        const title = localStorage.getItem('fcTitle');
        if (title) {
            this.fcTitle = title;
        }
        this.logoHeader = 'assets/imgs/logo/logo-h-br.svg';
        const logo = localStorage.getItem('fcLogoHeader');
    }

    private debugSyncDataFaileds(): void {
        this.queuesDBService.deleteOldFailedQueues().then(() => {
            this.queuesDBService.getNewestQueue('queues_faileds').then((queue) => {
                if (queue) {
                    console.error('[Erro de Sincronização] Falha na sincronização da fila', {
                        title: 'Erro de Sincronização de Fila',
                        location: 'AdminLayoutComponent.debugSyncDataFaileds',
                        queue: queue
                    });
                    this.debugService.sendDebugData({
                        subject: 'Existem erros de sincronização de fila (AdminLayoutComponent.debugSyncDataFaileds - admin-layout.component.ts)',
                        queue: queue,
                    });
                }
            });
        });
    }

    private setTitle(): void {
        this.title = this.routesService.getItem(this.router.url)?.pageTitle;
        this.titleService.setTitle(this.fcTitle + ' - ' + this.title + (localStorage.getItem('situation') === 'ACTIVE' ? ' | Ativo' : ''));
        const title = this.routesService.getItem(this.router.url)?.pageTitle;
        if (title) {
            if (title.includes('&nbsp;')) {
                this.title = this.currentClientService.client?.type !== 'provider' ? 'Cliente' : 'Fornecedor';
            } else {
                this.title = title;
            }
        }
    }
}
